import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import 'vue-awesome/icons/play.js';
import 'vue-awesome/icons/chevron-up.js';
import 'vue-awesome/icons/chevron-down.js';




// 引入字体库
import './assets/fonts/fonts.css'
import './assets/fonts/iconfont.css'



//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

// 请求间隔时间
Vue.prototype.$requestInterval = 60000 * 1000;


// 定义一个全局mixin函数 
Vue.mixin({
  methods: {
    // 计算x轴平均取点

    CalculateAveragePoints(originalArray) {
      if(!originalArray){
        return []
      }
      const desiredLength = 12;// x 轴上需要展示几个点
      const length = originalArray.length;

    
      // 直接处理原数组长度小于等于desiredLength（需要展示几个点）的情况
      if (length <= desiredLength) {
        return originalArray;
      }

      // 初始化结果数组
      let sampledArray = [];

      // 计算每次跳跃的步长，确保最后能取到最后一个元素
      // 这里我们先预留最后一个位置给原数组的最后一个元素
      let step = Math.ceil((length - 1) / (desiredLength - 1));

      // 从第一个元素开始，按步长添加元素到新数组
      for (let i = 0; i < (desiredLength - 1) * step; i += step) {
        sampledArray.push(originalArray[i]);
      }

      // 确保最后一个元素是原数组的最后一个元素
      sampledArray.push(originalArray[length - 1]);

      return sampledArray;
    }
  }
});

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
